aside#menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 2;
}

nav#cabezera.active~#menu {
    pointer-events: auto;
}


/* nav#cabezera.active~#menu .aside-left {
    transform: translateY(0);
} */

nav#cabezera.active~#menu .aside-left,
nav#cabezera.active~#menu .aside-right {
    transform: translateY(0);
}

nav#cabezera.active~#menu .loader {
    opacity: 1;
    transition: ease 50ms;

}

nav#cabezera~#menu .loader {
    opacity: 0;
    transition: ease 500ms;
    z-index: 1;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


nav#cabezera.active~#menu .topNav li.li_on {
    animation-name: menu-down;
    animation-duration: 100ms;
    animation-fill-mode: forwards;
}

@keyframes menu-down {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.aside-section {
    top: 0;
    bottom: 0;
    position: absolute;
}

.aside-left {
    z-index: 9;
    width: 24rem;
    left: 0;
    transform: translateY(-100vh);
}

#menu .background_img {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    background-size: cover;
    background-color: #242424;
    transition: transform .2s cubic-bezier(0.550, 0.085, 0.680, 0.530);
    transform: translateY(-100vh);
    filter: blur(2px);
}

nav#cabezera.active~#menu .background_img {
    transform: translateY(0vh);
}

/* nav#cabezera.active~#menu .background_img {
    animation-name: aperbackimg;
    animation-duration: 200ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes aperbackimg {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
} */

.aside-right {
    padding: 0;
    padding-left: 24rem;
    box-sizing: border-box;
    overflow: visible;
    width: 100vw;
    left: 0;
    transform: translateY(-100vh);
    transition: transform .2s cubic-bezier(0.550, 0.085, 0.680, 0.530);
    background: -moz-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 22%, rgba(0, 0, 0, 0.4962359943977591) 80%, rgba(0, 0, 0, 0.2637429971988795) 100%);
    background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 22%, rgba(0, 0, 0, 0.4962359943977591) 80%, rgba(0, 0, 0, 0.2637429971988795) 100%);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 22%, rgba(0, 0, 0, 0.4962359943977591) 80%, rgba(0, 0, 0, 0.2637429971988795) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024", endColorstr="#000000", GradientType=1);
}

.topNav {
    display: block;
    list-style: none;
    position: absolute;
    letter-spacing: -1px;
    box-sizing: border-box;
    font-family: 'Myriad Pro semiBold';
    height: fit-content;
    padding: 2rem 2rem;
    font-size: 150%;
    margin: auto;
    width: 100%;
    z-index: 1;
    bottom: 0;
    top: 2rem;
    right: 0;
    left: 0;
}

.sub2Nav li:not(.nav-header) {
    padding-left: .5rem;
}

.topNav li {
    display: block;
    position: relative;
    margin-bottom: 0.5rem;
    z-index: 2;
    color: white;
    transition: all ease 230ms;
    opacity: 0;
}

.topNav li.active::before {
    font-family: 'Material Icons';
}

.topNav li::before {
    display: inline;
    font-family: 'Material Icons Outlined';
    content: "label";
    -webkit-font-feature-settings: 'liga';
    font-size: .9rem;
}

.topNav .nav-header::before {
    content: "";
}

.topNav li span {
    display: inline;
    position: relative;
    text-decoration: none;
    margin-left: 1rem;
}


/* 
.topNav li span::after {
    background: none repeat scroll 0 0 transparent;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    background: #fff;
    transition: width ease 500ms;
    width: 0;
} */

.topNav li.not-active {
    /* color: #ffffffe3; */
}

.topNav li.active>span::after,
.topNav li span:hover::after {
    width: 100%;
}

.topNav li span:hover {
    cursor: pointer;
}

.topNav li.active {
    padding: 2rem 0;
}

.topNav li.not-active:hover {
    color: white;
}

.topNav li.not-active span:hover::after {
    width: 0;
}

.backMenu,
.backMenuColor {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

.backMenuColor {
    background-color: #fffffff2;
    z-index: 1;
}

.backMenu {
    z-index: 0;
}

.backMenu picture img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.backMenu picture:first-child {
    display: block;
}

.backMenu picture {
    display: none;
}

.closeNav {
    position: absolute;
    top: 2rem;
    right: 4rem;
    z-index: 3;
    cursor: pointer;
}

.closeNav .material-icons {
    font-size: 3.5rem;
}

.closeNav .txt {
    font-family: 'Myriad Pro Condensed';
    position: relative;
    display: block;
    text-align: center;
    margin-top: -.8rem;
    font-size: 1.5rem;
}

.subNav {
    display: none;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    flex-wrap: wrap;
    height: 100%;
    width: calc(100vw - 24rem);
    text-decoration: none;
    font-family: 'Myriad Pro Regular';
    letter-spacing: -1px;
    padding: 6rem 3rem;
    margin: 0;
    box-sizing: border-box;
    color: #ffffff;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
}

.subNav.active {
    display: flex;
    overflow: auto;
}


/* width */

.subNav::-webkit-scrollbar {
    width: 10px;
}


/* Track */

.subNav.subNav::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

.subNav::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sub2Nav:nth-child(1) {
    animation-delay: 50ms
}

.subNav.active>.sub2Nav:nth-child(2) {
    animation-delay: 50ms
}

.subNav.active>.sub2Nav:nth-child(3) {
    animation-delay: 100ms
}

.subNav.active>.sub2Nav:nth-child(4) {
    animation-delay: 150ms
}

.subNav.active>.sub2Nav:nth-child(5) {
    animation-delay: 200ms
}

.subNav.active>.sub2Nav:nth-child(6) {
    animation-delay: 250ms
}

.subNav.active>.sub2Nav:nth-child(7) {
    animation-delay: 300ms
}

.subNav.active>.sub2Nav:nth-child(8) {
    animation-delay: 350ms
}

.subNav.active>.sub2Nav:nth-child(9) {
    animation-delay: 400ms
}

.subNav.active>.sub2Nav:nth-child(10) {
    animation-delay: 450ms
}

.subNav.active>.sub2Nav:nth-child(11) {
    animation-delay: 500ms
}

.subNav.active>.sub2Nav:nth-child(12) {
    animation-delay: 550ms
}

.subNav.active>.sub2Nav {
    animation-name: menu-down-2;
    animation-duration: 300ms;
    /* animation-delay: 100ms; */
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes menu-down-2 {
    from {
        opacity: 0;
        margin-top: 0rem;
    }

    to {
        opacity: 1;
        margin-top: 2rem;
    }
}


/* nav#cabezera.active~#menu .subNav:first-child {
    display: flex !important;
    opacity: 1 !important;
} */

.sub2Nav>li {
    letter-spacing: -1px;
    cursor: default;
}

.sub2Nav>li>span {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.sub2Nav li span:hover {
    /* color: var(--main-red); */
    font-style: italic;
}

.sub2Nav {
    position: relative;
    display: flex;
    width: calc(50% - 4rem);
    min-width: 12rem;
    flex-flow: column;
    text-decoration: none;
    list-style: none;
    padding: 0;
    opacity: 0;
    margin-top: 0;
    margin-right: 2rem;
    padding-left: 1rem;
    border-left: 1px solid #242424;
}

.sub2Nav li {
    padding-bottom: .4rem;
    font-size: 1.2rem;
    font-family: 'Myriad Pro semibold';
}

.nav-header {
    font-family: 'Myriad Pro semiBold';
    margin-bottom: 0.5rem;
    letter-spacing: 0px;
    font-size: 77%;
    color: #9c9c9c;
    font-style: italic;
    font-size: 1.1rem !important;
    /* padding-top: 1.5rem !important; */
}

.subNav.active {
    opacity: 1;
    pointer-events: auto;
}

@media only screen and (min-width: 681px) and (max-width: 962px) {
    .aside-left {
        width: 260px;
    }

    .subNav {
        width: calc(100vw - 260px);
        
        padding: 6rem 0px;
    }

    .aside-right {
        padding-left: 270px;
    }

    .topNav {
        padding-right: 0px;
        padding-left: 1rem;
        margin-top: 75px;
    }
    .sub2Nav {
        margin-right: 0px;
    }
}


@media only screen and (max-width: 680px) {
    

    .topNav {
        margin-top: 90px;
    }
}