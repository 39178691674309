html,
#index,
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

body.noscroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.slide_title {
  position: relative;
  display: block;
  margin-bottom: 0 !important;
  margin-top: 2rem;
  /*margin: 2rem 0 6rem 0;*/
  font-size: 3.5rem;
  font-family: "Myriad Pro bold";
  color: black;
  /*height: 1em;*/
}

section {
  position: relative;
  display: block;
  /* height: calc(100vw / 2.026); */
  height: fit-content;
  box-sizing: border-box;
  padding: 3vh 7vw;
  padding-bottom: 0; 
  width: 100%;
}

#index {
  background: var(--soft-white);
}

#wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  z-index: 0;
}

nav#cabezera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  z-index: 3;
  pointer-events: auto;
}

#fixed_nav {
  display: flex;
  flex-flow: wrap;
  position: relative;
  background: transparent;
  padding: 0;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  justify-content: space-between;
  transition: ease 700ms;
  height: 5rem;
}

#fixed_nav.black {
  background: #0000007a;
  backdrop-filter: blur(1px);
}

#fixed_nav.blacode {
  background: #0000007a;
  /* backdrop-filter: blur(1px); */
}

#fixed_nav.blacode #govco-btn {
  border: 1px solid dimgrey;
}

#fixed_nav.blacode .hamburger-inner {
  background-color: #404040;
}

nav#cabezera.black_hold #fixed_nav {
  background: #0000007a !important;
  backdrop-filter: blur(1px) !important;
}

nav#cabezera #fixed_nav.nav_on {
  pointer-events: auto;
  transform: translateY(0);
}

nav#cabezera #fixed_nav.nav_off {
  pointer-events: none;
  transform: translateY(-8rem);
  transition: linear 200ms;
}

.nav_show {
  visibility: visible;
}

.nav_hidden {
  visibility: hidden;
}

/*hamburgerger menu*/

#hamburger {
  position: relative;
  display: block;
  background-color: var(--main-red);
  background-color: transparent;
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
  border-radius: 100%;
}

#hamburger.active {
  color: #414141;
}

#hamburger.active:hover {
  opacity: 0.8;
}

#hamburger.active:hover .hamburger-inner,
#hamburger.active:hover .hamburger-inner::after,
#hamburger.active:hover .hamburger-inner::before {
  opacity: 0.8;
}

.hamburger-box {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(1.5rem + 4px);
  height: 21px;
}

#hamburger .hamburger-inner {
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 3px;
  border-radius: 5px;
  background-color: #ffffff;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  margin: 2px;
}

#hamburger .hamburger-inner:nth-child(1) {
  top: 0px;
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hamburger .hamburger-inner:nth-child(2) {
  top: 6px;
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#hamburger .hamburger-inner:nth-child(3) {
  top: 12px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
    opacity 0.1s linear;
}

#hamburger.active .hamburger-inner {
  top: 6px;
}

#hamburger.active .hamburger-inner:nth-child(1) {
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  transform: rotate(-45deg);
}

#hamburger.active .hamburger-inner:nth-child(2) {
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
    transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
  transform: rotate(45deg);
}

#hamburger.active .hamburger-inner:nth-child(3) {
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s linear 0.22s;
  opacity: 0;
}

/* GOV */

#govco-btn {
  position: relative;
  height: fit-content;
  line-height: 100%;
  outline: 0 !important;
  font-size: 1.2rem;
  font-weight: bolder;
  letter-spacing: 1px;
  border: #ffffff solid 1px;
  padding: 0.35rem 1.3vw 0.35rem 1.6vw;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  color: #ffffff;
  background: #ffffff1c;
  width: fit-content;
  backdrop-filter: blur(5px);
  margin-right: 1.5vw !important;
}

/* #main-menu.active #govco-btn {
  color: #000000;
    border-color: #000000;
    background: #ffffff26; 
}*/

#govco-btn:hover {
  background: #065fd4 !important;
  border-color: transparent !important;
  color: #ffffffe8 !important;
  border-color: #ffffff00 !important;
}

/* Logo */

#logo_default {
  display: inline-block;
  position: relative;
  width: 18rem;
  height: auto;
  margin-left: 2rem;
  margin-top: 0.5rem;
  opacity: 0;
  z-index: 1;
  /* background-image: url("https://webufpso.ufpso.edu.co/media/general/logos/newsvg.png"); */
  background-size: contain;
  background-repeat: no-repeat;
}

#logo_default.animated {
  animation-name: bounce-top;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.6s;
}

#logo_default picture,
#logo_default picture img {
  display: block;
  width: 100%;
}

/* main menu*/

#main-menu {
  padding-right: 2rem;
  padding-top: 1rem;
  margin-top: 0.5rem;
  display: inline-flex;
  height: fit-content;
  flex-flow: wrap;
  position: absolute;
  background: transparent;
  align-items: center;
  z-index: 7;
  right: 0;
}

#main-menu.active #buscar,
#main-menu.active #traductor {
  color: #414141;
}

#main-menu .sub-main {
  position: relative;
  height: fit-content;
  display: inline-block;
  margin-left: 1.4rem;
  cursor: pointer;
  line-height: 0rem;
}

#buscar_box span {
  font-size: 1.7rem !important;
}

#main-menu span {
  position: relative;
  color: #ffffff;
  font-size: 1.6rem;
  z-index: 2;
}

#buscar_box input {
  display: block;
  position: absolute;
  height: 125%;
  width: 17rem;
  top: -17%;
  right: 1rem;
  color: white;
  background: #ffffff30;
  font-family: "Myriad Pro Light";
  font-size: 1.15rem;
  padding: 0 1rem;
  border: 0;
  outline: 0;
  opacity: 0;
  z-index: 1;
  transition: all ease 300ms;
}

#buscar_box input.active {
  opacity: 1;
}

#buscar_box input::placeholder {
  font-family: "Myriad Pro Regular";
  color: rgba(255, 255, 255, 0.479);
  font-size: 1.1em;
}

#main-menu .material-icons {
  font-weight: bolder;
}

#main-menu.active .material-icons {
  color: black !important;
}

#main-menu.active #buscar_box input {
  color: black;
  background: #52525230;
}

#main-menu.active #buscar_box input::placeholder {
  color: rgb(126, 126, 126);
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: 0.5em;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #333333;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.logoGov {
  width: 9rem;
  object-fit: contain;
}

@media only screen and (min-width: 0) and (max-width: 400px) {
  #logo_default {
    margin-left: 1rem;
    width: 18rem;
  }

  #main-menu {
    padding-right: 0;
    width: 100%;
    margin-top: 0rem;
  }
  #hamburger {
    margin-left: auto;
  }

  .hamburger-box {
    margin-right: rem;
    margin-top: 18px;
  }
  #govco-btn {
    position: absolute;
    top: 80px;
    left: 1rem;
  }
  .logoGov {
    width: 150px;
    object-fit: contain;
  }
  #fixed_nav {
    height: 8rem;
  }
}

@media only screen and (min-width: 401px) and (max-width: 680px) {
  #govco-btn {
    position: absolute;
    top: 80px;
    left: 2rem;
  }
  #main-menu {
    padding-right: 0;
    width: 100%;
    margin-top: 0rem;
  }
  #hamburger {
    width: 100%;
    margin-left: auto;
  }
  .hamburger-box {
    margin-right: 2rem;
    margin-top: 18px;
  }
  .logoGov {
    width: 150px;
    object-fit: contain;
  }
  #fixed_nav {
    height: 8rem;
  }
}
