/*responsive*/


/* Custom */

@media only screen and (min-width: 0) and (max-width: 600px) {

    /*Partner*/
    .logoBox {
        position: relative;
        padding: 0.5rem;
        width: 25%;
    }
}

@media only screen and (min-width: 0) and (max-width: 1310px) {

    /*Footer*/
    footer .footerBox {
        flex: none;
        text-align: center;
        width: 100%;
    }
}


/* Extra small devices (phones) */

@media only screen and (max-width: 600px) {

    /*Events*/
    .box_news_grad {
        display: none;
    }

    /*Links*/
    #grid_link,
    .self_link {
        justify-content: left !important;
    }

    /*Quote*/
    .quote_box {
        position: relative;
        display: block;
    }

    .quote_img {
        position: relative;
        width: calc(100vw - 4rem);
        height: 30vh;
        right: 0;
    }

    .quote_txt {
        width: calc(100vw - 4rem);
        margin: 0;
        padding: 4rem 2rem;
    }

    /*Events*/
    .sub_event {
        flex: 0 1 calc(100% - 1rem);
        flex-direction: column;
        height: auto;
    }

    .sub_event>.img_event,
    .sub_event>.txt_event {
        width: 100%;
    }

    .txt_event_title {
        margin-right: 4rem !important;
    }

    .txt_event_resume {
        width: calc(100% - 3rem);
    }
}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {

    /*Intro*/
    .side-bar {
        position: absolute;
    }

    /*News*/
    .box_news {
        width: calc(95vw - 4rem);
    }

    /*Events*/
    .sub_event {
        flex: 0 1 calc(100% - 1rem);
    }

    /*Basic*/
    .infoBox {
        flex-basis: 100%;
    }

    .box_news_grad {
        box-shadow: -1rem 0px 1.5rem 1rem white;
    }

    .infoBox.noCliped {
        order: 2;
        padding: 2rem;
    }

    .infoBox.cliped {
        height: 20vh;
    }
}


/* Large devices (laptops/desktops) */

@media only screen and (min-width: 768px) and (max-width: 992px) {

    /*News*/
    .box_news {
        width: 48vw;
    }

    /*Events*/
    .sub_event {
        flex: 0 1 calc(100% - 1rem);
    }

    /*Basic*/
    .infoBox.cliped {
        flex-basis: calc(30% - 3.5rem);
    }

    .infoBox.noCliped {
        flex-basis: 60%;
    }
}


/* Extra large devices (large laptops and desktop) */

@media only screen and (min-width: 1600px) {

    /*News*/
    .box_news .title {
        font-size: 2rem;
    }
}