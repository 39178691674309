.bodyContainer {
  display: flex !important;
  flex-direction: row;
  width: 100%;
}

@media screen and (width < 1000px) {
  .bodyContainer {
    flex-direction: column;
  }
}

caption {
  background-color: red;
  font-weight: bold;
  margin: 10px;
  color: #fff;
  text-align: center;
}

.table-bordered {
  border: #000 1px solid;
}
