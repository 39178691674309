/* Estilos generales para la sección */
section.slide[data-s="basic"] {
    height: fit-content;
    margin: 0;
}

/* Caja para el video */
.basic_box {
    position: relative;
    display: flex;
    width: 90%;
    margin: 2rem auto 0 auto; /* Añade margen para separar el video de los botones */
    padding-top: 50.625%; /* Mantiene una proporción 16:9 para el video */
}

.basic_box iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

/* Contenedor del texto */
.basic_txt {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los botones horizontalmente */
    justify-content: center; /* Centra el contenido verticalmente */
    margin: 0 auto 2rem auto; /* Añade margen inferior para separar los botones del video */
    max-width: 85%;
}

/* Estilo del título */
.slide_title {
    font-family: 'Myriad Pro Bold';
    font-size: 2.5rem;
    text-align: left; /* Centra el título */
    color: white;
}

/* Botones de selección de videos */
.basic_txt .resume {
    display: flex;
    flex-wrap: wrap; /* Permite que los botones se distribuyan en varias líneas si es necesario */
    justify-content: center; /* Centra los botones horizontalmente */
    gap: 1rem; /* Añade espacio entre los botones */
    margin-top: 1rem;
}

/* Estilos individuales de los botones */
.basic_txt .resume .read {
    font-family: 'Myriad Pro Bold';
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 0.6rem 2rem;
    text-align: center;
    background: #e2061a;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.basic_txt .resume .read:hover {
    background-color: #b50014; /* Color más oscuro al hacer hover */
}

/* Logo (si es necesario) */
.basic_box .utv {
    position: absolute;
    top: 2rem;
    left: 3rem;
    z-index: 2;
    width: 10rem;
}

.basic_box .utv img {
    width: 12vw;
}

/* Responsividad para pantallas pequeñas */
@media only screen and (max-width: 725px) {
    .basic_txt {
        position: static;
    }

    .slide_title {
        font-size: 2rem; /* Ajusta el tamaño del título para pantallas pequeñas */
    }

    .basic_txt .resume .read {
        font-size: 1.2rem;
        padding: 0.5rem 1.5rem; /* Reduce el padding en pantallas pequeñas */
    }
}
