#main_loader {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #0c0c0c;
    z-index: 9999;
}

#main_loader.hidden {
    display: none;
    pointer-events: none;
}

.no-scroll {
    overflow: hidden;
}

.loader_center {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: fit-content;
    height: fit-content;
    text-align: center;
}

.loader_in,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader_in {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 0, 0, 0.2);
    border-right: 1.1em solid rgba(255, 0, 0, 0.2);
    border-bottom: 1.1em solid rgba(255, 0, 0, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}