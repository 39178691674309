#error404 {
    position: absolute;
    display: block;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 71vw;
    height: fit-content;
    min-height: 0;
    padding: 0;
}

#error404 span {
    position: relative;
    display: block;
    text-align: center;
}

#error404 a {
    color: rgb(14, 62, 222);
}

#error404 span:nth-child(1) {
    font-family: 'Myriad Pro Bold';
    font-size: 4rem;
    color: var(--main-red);
    margin-bottom: 3rem;
}

#error404 span:nth-child(2) {
    font-family: 'Myriad Pro semiBold';
    font-size: 1.5rem;
}

#error404 span:nth-child(3) {
    font-family: 'Myriad Pro Light';
    margin-top: 1rem;
}