/*Enlaces de interes*/

section.slide[data-s="links"] {
    padding: 5rem 0;
    height: fit-content;
    /* padding-bottom: 0; */
}

#grid_link {
    opacity: 1;
    padding: 0rem 7vw;
    transition: opacity ease 0.5s;
    background-color: #d41224;
   /* background-image: linear-gradient( 335deg, #bd0d1e 23px, transparent 23px), linear-gradient( 155deg, #cd1627 23px, transparent 23px), linear-gradient( 335deg, var(--main-red) 23px, transparent 23px), linear-gradient( 155deg, #cd1627 23px, transparent 23px);*/
    background-size: 58px 58px;
    background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
}

#grid_link,
.self_link {
    position: relative;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
}

.self_link {
    font-family: 'Myriad Pro Bold';
    color: white;
    width: 18rem;
    height: 100%;
    display: flex; /* Flexbox para centrar */
    flex-direction: column; /* Coloca los elementos en columna */
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente */
    cursor: pointer;
}

.self_link a {
    text-decoration: none; /* Remueve el subrayado del enlace */
    display: flex; /* Flexbox para el contenido del enlace */
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    flex-direction: column; /* Coloca ícono y texto en columna */
}

.self_link .material-icons {
    font-size: 3rem;
    transition: ease .3s;
    margin-bottom: 0.5rem; /* Espacio entre ícono y texto */
}

.self_link .link_text {
    font-size: 1.1rem;
    text-align: center; /* Centra el texto */
    
}

.self_link:hover .link_text,
.self_link:hover .material-icons {
    opacity: .7;
}

.self_link .link_text {
    font-size: 1.1rem;
    text-align: center;
    max-width: 12rem;
    margin-left: 1rem;
    height: 2rem;
}

.carrousel {
   /* height: 100px;*/
    width: 100%;
}

.react-multi-carousel-item{
   /* margin: 0;*/
   padding: 8px;
} 

.button_arrow{
    left: 0px;
    position: absolute;
    display: block;
    z-index: 7;
    cursor: pointer;
    color: #fff;
    transition:   cubic-bezier( 0.445, 0.05, 0.55, 0.95) .3s;
}

.button_arrow-right {
    right: 0px;
    position: absolute;
    display: block;
    z-index: 7;
    cursor: pointer;
    color: #fff;
    transition:  cubic-bezier( 0.445, 0.05, 0.55, 0.95) .3s;
}

.show {
    animation: show 0.5s ease forwards;
}

.hide {
    animation: hide 0.5s ease forwards;
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

