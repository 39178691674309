/* Centrar el título y darle un margen superior del 7% */

.all-events-section h1 {
    text-align: center;
    margin-top: 7%;
    font-size: 2rem;
}



/* Centrar los botones de paginación */
.pagination {
    text-align: center;
    margin-top: 20px;
    padding: 1px 1px 15px 1px;
}

.pagination button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: #a50d1b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #a50d1b;
}

/* Ajustes adicionales para eventos */
.box_event_list_card {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    background-color: #fdfdfd;
}

.box_event_list_card_img_container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
}

.resume {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
}



.box_event_list_card {
    margin-top:10px;
    margin-bottom:10px;
    cursor: pointer;
    padding: 10px;
    background-color: #e3e5e5; 
    border-radius: 10px;
}

.box_event_list_card_main_container{
    display: flex;
    flex-direction: row;
    width:100%
}

.box_event_list_card_img_container {
    width:40%; 
    height:160px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 10px;
}

.imgList {
    border-radius:10px;
    width:100%;
    height:160px;
    object-fit: cover;
    object-position: left;
    transition: transform .3s ease-in;
}

.box_event_list_card_all {
    /*margin-top:10px;
    margin-bottom:10px;*/
    cursor: pointer;
    padding: 10px;
    background-color: #e3e5e5; 
    border-radius: 10px;
    margin: 10px;
}

.slide_title_all{
  padding: 7vh 0 0 0;
  text-align: center;
}