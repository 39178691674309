/* Flotante */

.mainFlotante {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    backdrop-filter: blur(3px);
    z-index: 7;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    pointer-events: none;
}

.mainFlotante.activa {
    opacity: 1;
    pointer-events: all;
    background: #4d4d4da3;
    transition: all 300ms ease-out;
}

.mainFlotante picture img {
    object-fit: fill;
    width: 100%;
    max-height: 100%;
}

.mainFlotante picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* margin: auto; */
    margin: auto;
    width: fit-content;
    max-width: 60vw;
    height: fit-content;
    border: 4px solid white;
    background: white;
    border-radius: 5px;
    transition: transform ease 300ms;
}

.mainFlotante picture:hover {
    transform: scale(1.01);
    transition: transform ease 500ms;
    cursor: pointer;
}

.mainFlotante .close_ {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: white;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.mainFlotante .close_ span {
    font-size: 2.5rem;
    background: #00000014;
    border-radius: 100%;
    transition: all ease-in 200ms;
}

.mainFlotante .close_ span:hover {
    transform: scale(1.05);
    transition: all ease-out 100ms;
    background: #0000002b;
}


/* access */

#access_box {
    position: absolute;
    display: block;
    left: -100%;
    top: 0;
    z-index: 9;
    height: 100vh;
    width: auto;
    transition: all ease 300ms;
}

#access_box img {
    height: 100%;
}

#access_box.active {
    left: 0;
}