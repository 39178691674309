/* Noticias */

section.slide[data-s="noticias"] {
    overflow-x: clip;
    height: fit-content;
}

.fondo{
    font-size: 20em;
    color: rgba(0, 0, 0, 0.486);
    text-align: center;
}

section.slide[data-s="inicio"] .arrow_circle_down {
    position: absolute;
    display: block;
    bottom: .5rem;
    right: 0;
    left: 0;
    width: fit-content;
    padding: 0.1rem;
    margin: auto;
    font-size: 3rem;
    color: #ffffff;
    background: transparent;
    border-radius: 100%;
    cursor: pointer;
}

section.slide[data-s="noticias"] .slide_title {
    display: block;
}


.slide_title {
    display: block;
    margin-bottom: 3rem;
}

.box_news {
    position: relative;
    display: block;
    width: 100%;
    height: 640px;
    margin: 0;
    cursor: pointer;
    margin-right: 1.5rem;
    background: #e3e5e5;
    float: left;
    border-radius: 10px;
}



.box_news_list_card {
    /*margin-top:10px;
    margin-bottom:10px;*/
    cursor: pointer;
    padding: 10px;
    background-color: #e3e5e5; 
    border-radius: 10px;
}

.box_news_list_card_main_container{
    display: flex;
    flex-direction: row;
    width:100%
}

.box_news_list_card_img_container {
    width:40%; 
    height:160px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 10px;
}


.box_news_list_card:hover .imgList {
    transform: scale(1.07);
    transition: transform .5s ease-in-out;
    text-decoration: underline;
    border-radius: 10px;
    height: 160px;
}

.box_news_list_card_img_container {
    width: 40%;
    height: 160px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 10px;
}

/* Imagenes con ajuste */
.imgList, .box_news .box_media img {
    border-radius: 10px;
    width: 100%;
    height: 240px;
    object-fit: fill;
    transition: transform 0.3s ease-in;
    will-change: transform;
}

.box_media {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin: 0;
}

.box_news .box_media>picture{
    display: block;
    height: 100%;
    transition: transform .3s ease-in;
}


.box_news:hover .box_media>picture {
    transform: scale(1.07);
    transition: transform .5s ease-in-out;
    text-decoration: underline;
    border-radius: 10px;
}

.box_news_grad {
    position: absolute;
    top: 38%;
    right: 0rem;
    width: 0rem;
    height: 55%;
    z-index: 1;
    box-shadow: -1rem 0px 5rem 7rem #ffffff;
}

.box_news .title {
    position: relative;
    display: -webkit-box;
    font-size: 1.3rem;
    font-family: 'Myriad Pro bold';
    line-height: 1.15;
    margin: 2rem 0;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.title {
    position: relative;
    display: -webkit-box;
    font-size: 1.3rem;
    font-family: 'Myriad Pro bold';
    line-height: 1.15;
    margin: 0;
    margin-bottom: 2rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.box_news .resume {
    position: relative;
    display: -webkit-box;
    font-size: 1.3rem;
    font-family: 'Myriad Pro Light';
    font-weight: unset;
    margin: 0;
    text-align: justify;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    line-height: 1.5;
}

.resume {
    position: relative;
    display: -webkit-box;
    font-size: 16px;
    font-family: 'Myriad Pro Light';
    font-weight: unset;
    margin: 0;
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5;
}

.box_news .box_content {
    display: block;
    position: relative;
    color: black;
    margin: auto;
    width: 100%;
    padding: 0rem 2rem;
    box-sizing: border-box;
    opacity: 1;
}

.slide_arrow {
    position: absolute;
    display: block;
    z-index: 7;
    top: 20px;
    cursor: pointer;
    transition: cubic-bezier( 0.445, 0.05, 0.55, 0.95) .3s;
}

.slide_arrow:hover {
    transform: scale(1.2);
}

.slide_arrow .material-icons {
    color: black;
    font-size: 2rem;
    font-weight: bolder;
}

.slide_arrow.right {
    right: 1vw;
}

.slide_arrow.left {
    right: 3vw;
}

.box_news_list::-webkit-scrollbar {
    width: var(--sb-size);
  }
  
  .box_news_list::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
  }
  
  .box_news_list::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;
  }
  
  @supports not selector(::-webkit-scrollbar) {
    .box_news_list {
        scrollbar-color: var(--sb-thumb-color)
                       var(--sb-track-color);
    }
  }

  @media screen and (max-width: 1250px) {
    .box_news_list {
        position: relative;
        display: block;
        width: 100%;
        height: 640px;
        opacity: 1;
        border-radius: 10px;
        overflow: auto;
        padding-right: 10px;
        margin-bottom: 20px;
        --sb-track-color: #f5f5f5;
        --sb-thumb-color: #3a3a3a;
        --sb-size: 10px;
    }

    .news_container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .box_news_container {
        position: relative;
        display: block;
        width: 100%;
        height: 640px;
        opacity: 1;
        margin-bottom: 20px;
    }
  }

  
  @media screen and (min-width: 1251px) {
    .box_news_list {
        position: relative;
        display: grid;
        gap: 10px;
        width: 45%;
        height: 640px;
        opacity: 1;
        border-radius: 10px;
        overflow: auto;
        padding-right: 10px;
        --sb-track-color: #f5f5f5;
        --sb-thumb-color: #3a3a3a;
        --sb-size: 10px;
    }

    .news_container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .box_news_container {
        position: relative;
        display: block;
        width: 50%;
        height: 640px;
        opacity: 1;
        
    }

  
  }
/* Asegúrate de que el contenedor padre tenga position: relative */
.news_container {
    position: relative; /* Para que los hijos se posicionen de forma absoluta respecto a este contenedor */
}

/* Botón ver todos */
.ver-todas-container {
    position: absolute; /* Cambiado a absolute */
    bottom: 10px; /* Ajusta la posición vertical como desees */
    right: 20px; /* Alinea a la derecha */
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 10px 10px;
}

.ver-todas-container a {
    padding: 10px 20px;
    background-color: #c71122;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.ver-todas-container a:hover {
    background-color: #a50d1b;
}