section.slide[data-s="inicio"] {
    /* background-image: url("../imagenes/logos/slide-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    overflow: hidden;
    min-height: 100vh;
    /*margin-bottom: 6rem;*/
}

.back-shadow {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.8;
    background: linear-gradient(0deg, hsla(0, 0%, 5%, .6), hsla(0, 0%, 5%, .6));
}


/* video de fondo */

#back_video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#control_video {
    position: absolute;
    bottom: 2vh;
    right: 2rem;
    color: #ffffff2b;
    font-size: 3rem;
    cursor: pointer;
}

#control_video:hover {
    color: #ffffff4d;
    font-size: 3rem;
}


/* Titulo promo */

.main-title {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 14vh;
    z-index: 1;
    width: calc(100% - 7vw);
    opacity: 0;
    animation-name: bounce-rotate;
    animation-fill-mode: forwards;
    animation-duration: .8s;
    text-align: center;
}

.main-title h2 {
    position: relative;
    display: block;
    font-size: 4.125rem;
    font-family: 'Myriad Pro Bold';
    color: white;
    /* text-shadow: 1px 1px 1px rgb(0, 0, 0); */
    margin: 0;
    margin-bottom: .5rem;
    width: 100%;
}

.main-title p {
    font-size: 1.25rem;
    font-family: 'Myriad Pro Light';
    color: white;
    margin: 0;
}

.main-title .study-us .material-icons {
    vertical-align: bottom;
    padding-right: 0.5rem;
    font-size: 120%;
}

.main-title .study-us {
    display: inline-block;
    font-size: 1rem;
    font-family: 'Myriad Pro Semibold';
    color: white;
    background: var(--main-red);
    padding: 0.75rem 1rem;
    margin-top: 2rem;
    line-height: 125%;
}

.main-title .study-us:hover {
    cursor: pointer;
    background-color: var(--main-b-red);
}

@media only screen and (min-width: 0) and (max-width: 400px)  {

    .main-title h2 {
        font-size: 3.125rem;
    }
    
}

