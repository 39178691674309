.szh-menu-button {
  width: 100%;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 14px;
  padding-right: 12px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
}

.szh-menu-button:hover {
  background-color: #c71122;
  color: #fff;
}

.szh-menu-button--open {
  background-color: #c71122;
  color: #fff;
}

.szh-menu__item {
  width: auto;
  border-radius: 10px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  transition: all 0.2s ease-out !important;
}

.szh-menu__item:hover {
  background-color: #c71122;
  color: #fff;
}

.szh-menu {
  display: flex;
  flex-direction: column;
  padding: 10px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.szh-menu__item--open {
  background-color: #c71122 !important;
  color: #fff;
}

.container {
  width: 18.8%;
  background-color: #ededed;
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 10px;
  padding-right: 10px;
  height: fit-content;
  margin-top: 2rem;
}
.container > div {
  margin-bottom: 10px;
}

.container > div:last-child {
  margin-bottom: 0;
}

.noMargin {
  margin: 0;
}

@media screen and (width < 1000px) {
  .container {
    width: 100%;
  }
}

ul > * {
  margin-bottom: 10px;
}

ul > div:last-child {
  margin-bottom: 0;
}

.text {
  text-align: left !important;
  font-size: 15px !important;
}
