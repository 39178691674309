#busqueda {
    position: relative;
    display: block;
    width: 100%;
    pointer-events: all;
    padding-top: 7rem;
    z-index: 1;
}

#busqueda .resultados_box,
#busqueda .busqueda_box {
    display: block;
    position: relative;
    height: fit-content;
    margin: auto;
    margin-top: 4rem;
}

#busqueda .resultados_box {
    box-sizing: border-box;
    padding: 3rem 4rem 5rem 4rem;
    min-height: 12rem;
    border-radius: 1rem;
}

#busqueda .resultados_box .resultado_item:first-child {
    border-radius: 1rem 1rem 0 0;
}

#busqueda .resultados_box .resultado_item:last-child {
    border-radius: 0 0 1rem 1rem;
    border: none;
}

#busqueda .resultados_box .resultado_item:hover {
    background-color: #f0f0f0;
}

#busqueda .resultados_box .resultado_item {
    position: relative;
    display: block;
    background-color: white;
    border-bottom: 1px solid #c1c1c1;
    padding: 1rem;
    cursor: pointer;
}

#busqueda .resultados_box .none {
    font-size: 1.1rem;
    font-family: 'Myriad Pro Light';
}

#busqueda .resultados_box .resultado_item .titulo {
    font-size: 1.5rem;
    font-family: 'Myriad Pro semiBold';
    margin-bottom: 1rem;
}

#busqueda .resultados_box .resultado_item .resumen {
    font-size: 1.2rem;
    font-family: 'Myriad Pro Light';
}

#busqueda .resultados_box .resultado_item .publicado {
    font-size: .79rem;
    font-family: 'Myriad Pro regular';
    color: rgb(0, 126, 204);
    margin-top: 1rem;
}

#busqueda .busqueda_box input:focus {
    border-bottom: 1px solid rgb(0, 126, 204);
}

#busqueda .busqueda_box input {
    display: block;
    height: 2rem;
    width: 40rem;
    margin: 5rem auto 1.5rem auto;
    font-family: 'Myriad Pro Light';
    font-size: 1.25rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid #545454;
    outline: none;
}

#busqueda .busqueda_box label {
    display: block;
    font-family: 'Myriad Pro Bold';
    font-size: 3.5rem;
    color: #4a4a4a;
    text-align: center;
}