.carouselContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin: 30px auto;
  }
  
  .logosContainer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    cursor: grab;
  }
  
  .logoSlider {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.5s ease-in-out;
  }
  
  .logoBox {
    flex: 0 0 20%; /* Cada logo ocupa el 20% del ancho para mostrar 5 logos */
    padding: 10px;
  }
  
  .logoBox img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    background: #ddd;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    transform: translateY(-50%);
    transition: background 0.3s ease;
  }
  
  .arrow.left {
    left: 0px;
  }
  
  .arrow.right {
    right: 0px;
  }
  
  .arrow:hover {
    background: #bbb;
  }
  