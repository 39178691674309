

section.slide[data-s="historico_noticias"] {
  /*Estaba comentado*/
    background: var(--soft-white); 
}

section.slide[data-s="noticias"].removed::before {
    content: none;
}

section.slide[data-s="noticias"].removed {
    padding-top: 3rem;
    padding-bottom: 1rem;
}

ul#historico_noticias {
    position: relative;
    display: block;
    padding: 0;
}

ul#historico_noticias>li {
    position: relative;
    display: flex;
    width: 100%;
    height: 30%;
    padding-bottom: 2rem;
}

ul#historico_noticias>li>picture {
    display: block;
    position: relative;
    height: 100%;
    width: 60vw;
    margin-right: 2rem;
}

ul#historico_noticias>li>picture>img {
    height: 100%;
    width: 100%;
}

ul#historico_noticias>li>span>h3 {
    font-family: 'Myriad Pro Bold';
    margin-top: 0;
    font-size: 1.7rem;
    color: var(--blue-black);
}

ul#historico_noticias>li>span>p {
    font-family: 'Myriad Pro Light';
    margin-top: 0;
    font-size: 1.5rem;
    color: var(--blue-black);
}