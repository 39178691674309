/* Animations */

@keyframes bounce-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    40% {
        opacity: .6;
        transform: translateY(5px);
    }

    100% {
        opacity: 0;
        transform: translateY(-200px);
    }
}

@keyframes bounce-top {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }

    60% {
        opacity: .7;
        transform: translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce-right {
    0% {
        opacity: .2;
        transform: translateX(200px);
    }

    60% {
        opacity: .7;
        transform: translateX(-15px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes bounce-rotate {
    0% {
        opacity: .2;
        transform: perspective(800px) rotateX(-80deg);
    }

    20% {
        opacity: 1;
    }

    60% {
        transform: perspective(800px) rotateX(20deg);
    }

    100% {
        opacity: 1;
        transform: perspective(800px) rotateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
