.info_content {
  position: relative;
  display: block;
  text-align: justify;
  min-height: 50vh;
  padding: 3vh 10vw;
  box-sizing: border-box;
}

.editor .info_component {
  display: block;
}

.edit_component[data-name="Iframe"] {
  position: relative;
  display: block;
  height: 50vh;
  margin: 2rem 0;
}

.editor .edit_component[data-name="Iframe"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #52525200;
  z-index: 1;
}

.edit_component[data-name="Iframe"] iframe {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 0.5rem;
}

/*Title*/

.edit_component[data-name="Subtitle"] h2 {
  font-size: 1.8rem;
  font-family: "Myriad Pro regular";
  margin: 0;
  letter-spacing: 0.75px;
}

.edit_component:not([data-name="Hr"]) + .edit_component[data-name="Hr"],
.edit_component:not([data-name="Subtitle"])
  + .edit_component[data-name="Subtitle"],
.edit_component:not([data-name="Title"]) + .edit_component[data-name="Title"] {
  padding-top: 3rem;
}

.edit_component[data-name="Title"] + .edit_component[data-name="Subtitle"] {
  padding-top: 2rem;
}

.edit_component[data-name="Link"] + .edit_component[data-name="Table"] {
  padding-top: 2rem;
}

.edit_component[data-name="Hr"] + .edit_component[data-name="List"] {
  padding-top: 2rem;
}

/* Estilos segun orden */

.edit_component[data-name="Accordion"] + .edit_component[data-name="Subtitle"] {
  padding-top: 2rem;
}
.edit_component[data-name="Accordion"] ~ .edit_component[data-name="Subtitle"] {
  padding-top: 2rem;
}
.edit_component[data-name="Accordion"] ~ .edit_component[data-name="Subtitle"] ,
.edit_component[data-name="Accordion"] ~ .edit_component[data-name="Parag"],
.edit_component[data-name="Accordion"] ~ .edit_component[data-name="Title"]
{
  padding : 1rem 2rem;
}

.edit_component[data-name="Hr"] + .edit_component[data-name="Hr"] {
  padding-top: 1.5rem;
}

.edit_component[data-name="Hr"] hr {
  border: 0;
  background: #d5d5d5;
  border-bottom: 2px solid #d5d5d5;
  height: 1px;
  margin: auto;
  width: 100%;
}

.edit_component[data-name="Title"] {
  padding-top: 2rem;
}

.edit_component[data-name="Title"] h1 {
  font-size: 2rem;
  color: var(--main-red);
  font-family: "Myriad Pro semibold";
  letter-spacing: 0.75px;
  margin: 0;
}

.edit_component {
  padding-top: 2rem;
}

.edit_component[data-name="Parag"] p {
  font-size: 1.4rem;
  font-family: "Myriad Pro Light";
  margin: 0;
  line-height: 1.5;
}

.edit_component[data-name="List"] a,
.edit_component[data-name="Parag"] a,
.edit_component[data-name="Table"] a {
  color: #008cdf !important;
}

.edit_component[data-name="List"] a:hover,
.edit_component[data-name="Parag"] a:hover,
.edit_component[data-name="Table"] a:hover:not(.inned > a) {
  text-decoration: underline;
  cursor: pointer;
}

.edit_component[data-name="Image"] {
  position: relative;
  display: block;
  padding-bottom: 4rem;
  height: fit-content;
  text-align: center;
}

.edit_component[data-name="Image"] img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.edit_component[data-name="Image"] img.aspect_ratio.R75 {
  max-width: 75%;
}

.edit_component[data-name="Image"] img.aspect_ratio.R45 {
  max-width: 45%;
}

.edit_component[data-name="Image"] img.aspect_ratio.R25 {
  max-width: 25%;
}

.edit_component[data-name="Image"] img.aspect_ratio.R15 {
  max-width: 15%;
}

.edit_component[data-name="HighlightText"] {
  position: relative;
  display: block;
  width: initial;
  border: 0;
  outline: none;
}

.edit_component[data-name="HighlightText"] div {
  font-size: 1.2rem;
  color: white;
  font-family: "Myriad Pro light";
  padding: 1rem 2rem;
  background: #4894c1;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.material-icons.inned {
  border: none;
  position: relative;
  display: inline-flex;
  font-size: 1.3rem;
  height: 100%;
  align-items: center;
  opacity: 1;
  padding: 0 0.5rem;
  cursor: default;
  transition: transform ease-in-out 100ms;
}

.material-icons.inned:hover {
  transform: scale(1.1);
}

.material-icons.inned > a,
.material-icons.inned > a:visited {
  color: #008cdf;
}

.edit_component[data-name="List"] ul {
  list-style: none;
  font-family: "Myriad Pro Light";
  font-size: 1.3rem;
  padding: 1.5rem 4rem;
  background: #dddddd;
  margin: 0rem;
  color: black;
  border-radius: 0.25rem;
}

.edit_component[data-name="List"] ul li::before {
  content: "\2022";
  color: #767676;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -2rem;
  padding-right: 1rem;
  font-size: 2rem;
}

.edit_component[data-name="List"] ul li {
  padding-bottom: 0.25rem;
}

/*Acordion*/

.edit_component[data-name="Accordion"] {
  display: block;
  background: transparent;
  margin: 0 2rem;
}

.edit_component[data-name="Accordion"].acc_final {
  padding-top: 0;
  background: white;
}

.folder_accordion {
  position: relative;
  display: block;
  width: inherit;
  height: auto;
  font-family: "Myriad Pro Bold";
}

.folder_button {
  display: block;
  position: relative;
  background-color: #343434;
  border: none;
  /* border-bottom: 2px solid #242424; */
  color: #ffffff;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 1.2rem;
  /* transition: 0.4s; */
  box-sizing: border-box;
  font-family: "Myriad Pro Bold";
  margin: auto;
}

.folder_button.active,
.folder_button:hover {
  background-color: #484848;
}

.folder_button::after {
  content: "\e5ce";
  color: #fff;
  float: right;
  font-size: 2rem;
  line-height: 1.5rem;
  font-family: "Material Icons";
}

.folder_button.closed:after {
  content: "\e5cf";
}

.folder_button::before {
  content: "\e2c7";
  color: #767676;
  float: left;
  margin-left: 0.5rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  font-family: "Material Icons";
}

.folder_accordion.active .folder_button:after {
  content: "\2212";
}

.folder_content {
  font-family: "Myriad Pro Light";
  font-size: 1.2rem;
  /* padding: 0 1rem; */
  background-color: #d4d4d4;
  color: #2b2b2b;
  max-height: auto !important;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
  margin-bottom: 1rem;
}

.folder_fix {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: wrap;
  margin: 0.35rem;
  justify-content: space-between;
  box-sizing: content-box;
  height: 4rem;
}

.folder_fix .fix_content {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  font-family: "Myriad Pro Bold";
  color: #5b5b5b;
  letter-spacing: 0.025rem;
}

.folder_fix span {
  display: flex;
  padding: 0.5rem 0rem;
  height: fit-content;
  max-width: 80%;
  letter-spacing: 1px;
}

.folder_fix:hover {
  font-weight: bold;
  cursor: default;
}

.folder_fix p.download {
  display: flex;
  margin: 0;
  color: var(--main-blue);
}

.folder_fix p.download:hover::before,
.folder_fix p.download:hover {
  color: #396cde;
}

.folder_fix p.download::before {
  content: "\e2c4";
  color: var(--main-blue);
  font-size: 1.5rem;
  font-family: "Material Icons";
  padding-right: 0.75rem;
}

strong {
  font-family: "Myriad Pro semiBold";
}

.edit_component[data-name="Accordion"].active .cell_selected,
.edit_component[data-name="Accordion"].active
  .folder_fix
  span.babycell_selected {
  outline: 4px dashed #4894c1;
  border: 0;
  z-index: 1;
}

.edit_component[data-name="Accordion"].active .cell_selected {
  outline: 3px dashed #4894c1;
}

.edit_component[data-name="Accordion"].active
  .folder_fix
  span.babycell_selected {
  outline: 2px dashed #4894c1;
}

.edit_component[data-name="Accordion"].active .folder_fix span {
  position: relative;
}

.edit_component[data-name="Accordion"].active
  .folder_fix
  span.babycell_selected::after {
  position: absolute;
  top: -4px;
  right: -5px;
  color: #008cdf;
  font-size: 1.4rem;
  font-family: "Material Icons";
  content: "\e146";
  z-index: 1;
}

/*Link component*/

.edit_component[data-name="Link"]:not(.info_component) {
  width: fit-content;
  display: inline-block;
}

.edit_component[data-name="Audio"] > .audio_player,
.edit_component[data-name="File"] > .file_link,
.edit_component[data-name="Link"] > .file_link {
  position: relative;
  display: block;
  background: #4894c1;
  font-family: "Myriad Pro Bold";
  font-size: 1.25rem;
  letter-spacing: 0.5px;
  color: white !important;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  max-width: 100%;
}

.edit_component[data-name="File"] > .file_link:hover,
.edit_component[data-name="Link"] > .file_link:hover {
  background: #427695;
}

.edit_component[data-name="Link"] > .file_link:visited {
  text-decoration: none;
}

.edit_component[data-name="Audio"] > .audio_player::before,
.edit_component[data-name="File"] > .file_link::before,
.edit_component[data-name="Link"] > .file_link::before {
  font-family: "Material Icons";
  color: #ffffff;
  display: inline-block;
  vertical-align: bottom;
  height: fit-content;
  font-size: 1.5rem;
  margin-right: 1rem;
}

.edit_component[data-name="Audio"] > .audio_player::before {
  content: "audiotrack";
}

.edit_component[data-name="Link"] > .file_link::before {
  content: "link";
}

.edit_component[data-name="File"] > .file_link::before {
  content: "text_snippet";
}

/* Audio Component */

.edit_component[data-name="Audio"] > .audio_player {
  padding-right: 6rem;
  padding-bottom: 1rem;
}

.edit_component[data-name="Audio"] > .audio_player > .controls > button {
  display: inline-block;
  background: transparent;
  outline: 0;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  border: 0;
}

.edit_component[data-name="Audio"] > .audio_player > .controls > .player_button,
.edit_component[data-name="Audio"] > .audio_player > .controls > .sound_button {
  margin-right: 1rem;
}

.edit_component[data-name="Audio"] > .audio_player > .controls {
  display: block;
  position: absolute;
  width: fit-content;
  top: 0.2rem;
  right: 0.5rem;
  height: 3rem;
  padding: 0.5rem 0rem;
}

.edit_component[data-name="Audio"]
  > .audio_player
  > .controls
  > .material-icons {
  display: inline-block;
  color: #4894c1;
  padding: 0.2rem;
  font-size: 1.2rem;
  margin: 0;
  width: fit-content;
  height: fit-content;
  background: white;
  border-radius: 100%;
  margin-right: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.edit_component[data-name="Audio"]
  > .audio_player
  > .controls
  > .material-icons:hover {
  background: rgb(236, 236, 236);
}

.edit_component[data-name="Audio"] > .audio_player > .timeline {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  bottom: 0;
  left: 0;
  height: 0.45rem;
  outline: none;
  border-radius: 0 0 0.5rem 0.5rem;
  background: #c9c9c9;
  background-size: 0% 100%;
  background-image: linear-gradient(#a1a1a1, #a1a1a1);
  background-repeat: no-repeat;
  /* border: 1px solid #b1b1b1;
    border-top: 0; */
}

.edit_component[data-name="Audio"]
  > .audio_player
  > .timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.75rem;
  height: 0.75rem;
  background: rgba(82, 82, 82, 0.616);
  cursor: pointer;
  border-radius: 100%;
}

.edit_component[data-name="Audio"]
  > .audio_player
  > .timeline::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.edit_component[data-name="Audio"] > .audio_player > .audio_text {
  display: inline-block;
}

.edit_component[data-name="Audio"] audio {
  display: none;
}

.edit_component[data-name="Audio"]:not(.info_component) {
  display: inline-block;
  margin-right: 2rem;
}

/*Acorderon final closure*/

.edit_component[data-name="Accordion"] > span.lastacc {
  display: block;
  width: 100%;
  margin: auto;
  height: 0.55rem;
  border-top: 1px solid #ededed;
  background: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 1px,
    transparent 1px,
    transparent 6px
  );
  background-color: #343434;
  background-size: 8px 8px;
  border-radius: 0px 0px 5px 5px;
}

/* destacado */

.edit_component[data-name="HighlightText"] > .red {
  background: #d72636;
}

.edit_component[data-name="HighlightText"] > .blue {
  background: #4894c1;
}

.edit_component[data-name="HighlightText"] > .green {
  background: #5ca78b;
}

.edit_component[data-name="HighlightText"] > .black {
  background: #2b2b2b;
}

/* table */

.edit_component[data-name="Table"] table {
  position: relative;
  display: table;
  width: 100%;
  min-height: 4rem;
  border-collapse: collapse;
  margin-bottom: 4rem;
}

.acc_box table {
  margin-bottom: 0 !important;
}

.edit_component[data-name="Table"] table th {
  border: none;
}

.edit_component[data-name="Table"] table td,
.edit_component[data-name="Table"] table th {
  padding: 1rem;
  text-align: left;
  position: relative;
  cursor: text;
  border-bottom: 1px solid #dddddd;
}

.edit_component[data-name="Table"] table thead,
.edit_component[data-name="Table"] table tbody {
  font-size: 1.35rem;
}

.edit_component[data-name="Table"] table thead {
  font-family: "Myriad Pro Bold";
  background: transparent;
  color: #5a5a5a;
  border-bottom: 2px solid gray;
}

.edit_component[data-name="Table"] table tbody {
  font-family: "Myriad Pro Light";
  background-color: transparent;
  color: #2b2b2b;
}

.edit_component[data-name="Table"].active table thead tr th.cell_selected,
.edit_component[data-name="Table"].active table tbody tr td.cell_selected {
  outline: 2px dashed #4894c1;
}

.edit_component[data-name="Table"].active
  table
  tbody
  tr
  td.cell_selected::before {
  position: absolute;
  top: -4px;
  right: -5px;
  color: #008cdf;
  font-size: 1.4rem;
  font-family: "Material Icons";
  content: "\e146";
  /* -webkit-font-feature-settings: 'liga'; */
  cursor: pointer;
}

.ver-btn {
  color: #525252;
}

.ver-btn:hover {
  color: #2e2e2e;
}

.edit_component[data-name="Table"].prev {
  background: #d3d3d3;
  color: #adadad;
  font-size: 1rem;
}

.edit_component[data-name="Table"].prev table::after {
  content: "table_view";
  font-family: "Material Icons";
  font-size: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.info_content .info_component {
  display: none !important;
}

.edit_component.acc_box {
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  background-color: #ffffff;
  padding: 0;
}

div.edit_component[data-name="Link"].acc_box {
  width: calc(100% - 4rem);
}

.edit_component[data-name="File"]
  + .edit_component:not(.edit_component[data-name="File"]),
.edit_component[data-name="Link"]
  + .edit_component:not(.edit_component[data-name="Link"]) {
  padding-top: 3rem;
}

.edit_component:not(.edit_component[data-name="File"])
  + .edit_component[data-name="File"],
.edit_component:not(.edit_component[data-name="Link"])
  + .edit_component[data-name="Link"] {
  padding-top: 3rem;
}

/* Image */

.edit_component[data-name="Image"] img.aspect_ratio {
  max-width: 50%;
}

.edit_component[data-name="Image"] img {
  max-width: 100%;
  position: relative;
  display: block;
  margin: auto;
}

.edit_component[data-name="Image"] {
  background: transparent;
  /* height: 100%; */
  display: block;
}

/* Iframe */

.edit_component[data-name="Iframe"] {
  position: relative;
  height: 35rem;
  margin: 0;
}

.edit_component[data-name="Iframe"].info_component {
  height: auto;
}

/**Pensum**/
.edit_component[data-name="Pensum"] {
  position: relative;
  display: block;
  height: 50vh;
  margin: 2rem 0;
}

.editor .edit_component[data-name="Pensum"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #52525200;
  z-index: 1;
}

.edit_component[data-name="Pensum"] {
  margin-top: 20px;
  height: auto;
  border-radius: 10px;
  padding: 0;
}

.edit_component[data-name="Pensums"].info_component {
  height: auto;
}
