.container-item {
  display: flex;
  flex-direction: column;
  align-content: "center";
  align-items: "center";
}

.container-item-form {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.item-name-label {
  margin-bottom: 0px !important;
  min-width: 20%;
}

.container-types-forms {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.container-types {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
}

.button-types {
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.item-found {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.block {
  display: block !important;
}

.pointer {
  cursor: pointer !important;
}

.item-list {
  max-height: 205px;
  overflow: auto;
}

.item-border {
  border: 1px solid #c71122 !important;
}

.containerPhone {
  background-color: #c71122;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  color: white;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: row;
  inline-size: auto;
}

.titleDirectorio {
  width: 100%;
  border-radius: 10px;
  background-color: #c71122;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  margin-bottom: 10px;
}

.containerDirectorio {
  max-height: 65vh;
  overflow: auto;
  white-space: nowrap;
  padding-right: 10px;
}

.containerDirectorio::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.containerDirectorio::-webkit-scrollbar-track {
  background: #8f8f8f;
}

.containerDirectorio::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 0.5rem;
}

.containerDirectorio::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.groupTitle {
  margin-top: 30px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #c71122;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.seccionContainer {
  background-color: #bdbcbc;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.containerDepends {
  width: 100%;
  min-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  text-wrap: wrap;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dependName {
  justify-content: flex-start;
  width: 100%;
}

.ext {
  justify-content: flex-end;
  width: 50%;
  display: flex;
}

.noDepends {
  width: 100%;
  height: 20px;
  background-color: #b3b3b3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.inputDirectorioContainer {
  position: relative;
}

.searchIconContainer {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  pointer-events: none;
  margin-left: 10px;
  margin-right: 10px;
}

.searchIcon {
  width: 25px;
  height: 30px;
  color: #6b7280;
}

.inputDirectorio {
  display: block;
  border-radius: 10px;
  border: none;
  width: 100%;
  line-height: 1.25rem;
  background-color: #d9d9d9;
  padding-left: 50px;
  padding-right: 20px;
  font-size: 22px;
  font-weight: bold;
  color: black;
  margin-bottom: 30px;
  height: 40px;
}

.backgroundDirectorio {
  background-color: #d9d9d9;
  padding: 30px;
  border-radius: 10px;
}

.actionButton {
  display: block;
  cursor: pointer;
}
