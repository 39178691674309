/* Contenedor de eventos */
.box_event {
    display: flex;
    flex-wrap: nowrap; /* No permitir que los eventos se desborden en múltiples líneas */
    justify-content: center; /* Centrar el contenido */
}

/* Contenedor principal del evento */
.main_event {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
    overflow: auto;
    width: 100%; /* Asegura que ocupe el 100% del ancho */
}

.main_event:last-child {
    width: 74vw;
}

/* Imagen del evento: Adaptación fluida y zoom */
.main_event .img_event,
.sub_event .img_event {
    display: flex;
    justify-content: flex-start; /* Alinea el contenido a la izquierda */
    align-items: center; /* Centra verticalmente el contenido */
    padding-right: 20px; /* Ajusta el padding a la derecha según sea necesario */
    height: 100%; /* Asegúrate de que el contenedor tenga altura definida */
    width: 45%;
    padding-left: 1.4%;
}

.main_event .img_event img,
.sub_event .img_event img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

/* Efecto de zoom suave en hover */
.main_event:hover .img_event img,
.sub_event:hover .img_event img {
    transform: scale(1.07);
}

.main_event .img_event::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(165deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0.25) 60%);
}

.main_event .title_event {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-family: 'Myriad Pro semibold';
    font-size: 5.5vw;
    color: rgba(255, 255, 255, 0.89);
    text-align: center;
}

/* Contenedor de cada evento */
.sub_event {
    display: flex;
    position: relative;
    /*margin: 0.5rem;*/
    height: 28vh;
    border-radius: 10px;
    background: rgba(128, 128, 128, 0.13);
    flex: 0 0 100%; /* Forzar que siempre se muestre un solo evento */
    overflow: hidden;
    transition: background 0.3s ease, opacity 0.5s ease;
    opacity: 1;
}

.sub_event:hover {
    background: rgba(128, 128, 128, 0.15);
    cursor: pointer;
}

/* Texto dentro del sub_event */
.sub_event .txt_event {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-inline: 18px;
    width: 55%; /* El contenedor de texto ocupará el 55% */
    /*margin-left: 2rem;
    padding-right: 1.5rem;*/
    height: 100%;
}

.sub_event .txt_event_title {
    font-family: 'Myriad Pro Bold';
    font-size: 1.5rem;
    /*margin: 1rem 0;*/
    line-height: 1.4rem;
    word-wrap: break-word;
    white-space: normal;
}

.sub_event .txt_event_resume {
    font-family: 'Myriad Pro Light';
    margin-bottom: 1rem;
    /*margin-right: 4%;*/
    font-size: 1.3rem;
    line-height: 1.6rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    /*max-height: 5rem;*/
    text-align: justify;
    color: #666;
}

.sub_event .txt_event_date {
    color: #636363;
    font-family: 'Myriad Pro regular';
    font-size: 0.9rem;
    text-align: left;
   /* margin-top: auto;*/
    /*padding: 0 0.5rem 0.5rem;*/
}

/* Carrusel */
.carousel_container {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.carousel {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
    white-space: nowrap;
}

/* Botones de navegación del carrusel */
.carousel_button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 24px;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.carousel_button.left {
    left: 0;
}

.carousel_button.right {
    right: 0;
    padding-left: 1rem;
}

.carousel_button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Botón ver todos */
.view_all_button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: bold;
}

.view_all_button a {
    padding: 10px 20px;
    background-color: #c71122;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.view_all_button a:hover {
    background-color: #a50d1b;
}

/* Animación de desvanecido */
.fade-in,
.fade-out {
    opacity: 0;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.fade-in {
    animation-name: fadeIn;
    opacity: 1;
}

.fade-out {
    animation-name: fadeOut;
}

.slide_arrow {
    position: absolute;
    display: block;
    z-index: 7;
    top: 20px;
    cursor: pointer;
    transition: cubic-bezier( 0.445, 0.05, 0.55, 0.95) .3s;
}

.slide_arrow:hover {
    transform: scale(1.2);
}

.slide_arrow .material-icons {
    color: black;
    font-size: 2rem;
    font-weight: bolder;
}

.slide_arrow.right {
    right: 1vw;
}

.slide_arrow.left {
    right: 3vw;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
