/* Wide.css */

.slide {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  
  .slide_title {
    display: block;
    margin-bottom: 3rem  !important;
    color: black;
  }
  
  .box_carousel {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    /*padding: 20px;*/
  }
  
  .self_carousel {
    position: relative;
    border-radius: 10px;
  }
  
  .img_carousel video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.4); /* Ajusta la opacidad del video */
    z-index: 1;
  }
  
  .txt_carousel {
    /*position: relative;*/
    z-index: 2;
    /*padding: 7%;*/
    /*border-radius: 5px;*/
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    /*align-items: center;*/
    text-align: justify;
    /*height: 100%;*/
    /*background-color: rgba(0, 0, 0, 0.7);*/
  }
  
  .txt_carousel div {
    /*width: 100%;*/
    font-weight: bold;
  }
  
  .read {
    margin-top: 10px;
    display: inline-block;
    background-color: #c71122;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    text-align: center;
  }
  
  
  
  /*.material-icons {
    margin-right: 5px;
  }*/

 
  