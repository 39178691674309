#servicios {
    padding-top: 150px;
    padding-bottom: 20px;
    padding-left: 112px;
    padding-right: 112px;
    z-index: 1;
    min-height: 100vh;
    position: relative; /* Necesario para posicionar el ::after */
}

/* Fondo oscuro semitransparente detrás de los servicios */
#servicios::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro */
    z-index: 0; /* Detrás de los servicios */
}

/* Estilo del título */
#servicios > .title_servicios {
    font-family: 'Myriad Pro bold';
    font-size: 56px;
    color: #FFFFFF; /* Blanco puro */
    text-align: center;
    position: relative;
    z-index: 1; /* Encima del fondo oscuro */
}

/* Caja de servicios */
#servicios > .servicios_box {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; /* Encima del fondo oscuro */
}

/* Estilos de los elementos individuales de servicio */
#servicios .servicios_item {
    display: inline-flex;
    margin: 1.5%;
    padding: 16px;
    border: 2px solid white;
    font-family: 'MYRIAD PRO semibold';
    font-size: 19px;
    border-radius: 10px;
    background-color: #c71122;
    cursor: pointer;
    color: white;
    width: 26%;
    align-items: center;
}

#servicios .servicios_item:hover {
    background: #a8303b;
}

#servicios .servicios_item .servicios_name {
    text-transform: capitalize;
}

#servicios .servicios_item .material-icons {
    margin-right: 1rem;
}

/* Botón de control de video */
#control_video {
    position: absolute;
    bottom: 2vh;
    right: 2rem;
    color: #ffffff2b;
    font-size: 3rem;
    cursor: pointer;
    z-index: 1; /* Encima del fondo oscuro */
}

.control-video-btn {
    display: contents;
}

#control_video:hover {
    color: #ffffff4d;
}

/* Ajustes responsivos */
@media only screen and (min-width: 681px) and (max-width: 964px) {
    #servicios .servicios_item {
        width: 40%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 680px) {
    #servicios .servicios_item {
        width: 90%;
    }

    #servicios {
        padding-left: 40px;
        padding-right: 40px;
    }
}
