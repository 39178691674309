/* Centrar el botón de búsqueda */
.search-bar {
    text-align: center;
    position: relative;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.search-bar input {
    padding: 16px 16px  16px 47px;
    border-radius: 25px;
    border: 1px solid #e3e5e5;
    width: 100%;
    font-size: 1rem;
    background: #e3e5e5;
}

.search-bar input:focus-visible {
    border: 1px solid #9e9e9e1c;
    outline: none;
}

.search{
    position: absolute;
    left: 17px;
    top: 14px;
}