.containerSemestre {
  background-color: rgb(199, 17, 34);
  width: 100%;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: bold;
  padding: 6px;
  border-radius: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}

table {
  width: 100%;
  margin: 20px auto;
  border-collapse: collapse; /* Para fusionar los bordes de las celdas */
  border-radius: 10px;
  overflow: hidden;
}

th,
td {
  padding: 10px 8px; /* Padding uniforme en todas las direcciones */
  text-align: center;
  border: none; /* Borde de las celdas */
  border-bottom: 1px solid white; /* Borde superior de todos los th */
  border-right: 1px solid white;
  font-size: 14px;
}

th:last-child,
td:last-child {
  border-right: none; /* Quita el borde derecho del último th */
}

tr:last-child td {
  border-bottom: none; /* Quita el borde inferior del último tr */
}

thead {
  background-color: #676767;
  color: #fff;
}

tbody {
  background-color: #dbdbdb;
  color: #000;
}

.containerTable {
  display: flex;
  overflow: auto;
}

.containerTable::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.containerTable::-webkit-scrollbar-track {
  background: #8f8f8f;
}

.containerTable::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 0.5rem;
}

.containerTable::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.containerPensum {
  width: 100%;
  background-color: #ededed;
  border-radius: 10px;
  padding: 15px 20px;
  box-sizing: border-box;
}
