:root {
    --main-red: #c71122;
    --main-b-red: #e4162a;
    --soft-white: #f3f4f4;
    --blue-black: #14171c;
    --soft-gray: #bfbfbf;
    --main-gray: #242424;
    --main-blue: rgb(0, 113, 179);
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}