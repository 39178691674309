/* Estilos de la barra lateral en pantallas grandes */
.side-bar {
  position: fixed !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 0.5rem;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
  z-index: 2;
  margin: auto;
  transition: margin-right .3s cubic-bezier(0.45, 0.25, 0.60, 0.95);
  padding: 5px;
  background: #c71122;
  width: 2em; /* Tamaño original en pantallas grandes */
}

/* Contenedor de cada botón */
.side-button-container {
  position: relative;
  width: 100%;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos de los botones */
.side-button-container .material-icons {
  color: #ecf0f1;
  cursor: pointer;
  font-size: 24px; /* Tamaño original en pantallas grandes */
  transition: color 0.3s ease, font-size 0.3s ease;
}

.side-button-container .material-icons:hover {
  color: #3498db;
}

/* Recuadro que aparece al hacer hover */
.hover-box {
  position: absolute;
  right: 45px;
  display: none;
  align-items: center;
  background: #00000079;
  backdrop-filter: blur(1px);
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateX(-10px);
  z-index: 1000;
}

/* Icono grande dentro del recuadro */
.large-icon {
  font-size: 35px;
  margin-right: 10px;
}

/* Título dentro del recuadro */
.hover-title {
  color: #ecf0f1;
  font-size: 16px;
  font-weight: bold;
}

/* Mostrar el recuadro al hacer hover */
.side-button-container:hover .hover-box {
  display: flex;
  opacity: 1;
  transform: translateX(0);
}

/* --- Adaptaciones solo para pantallas más pequeñas --- */
@media (max-width: 768px) {
  .side-bar {
      width: 8vw; /* Ancho proporcional solo en pantallas pequeñas */
      padding: 1vw; /* Ajustar el padding */
  }
  .hover-box {
      left: 10vw;
      right: auto;
  }
  .side-button-container .material-icons {
      font-size: 5vw; /* Tamaño de íconos proporcional en pantallas pequeñas */
  }
  .large-icon {
      font-size: 6vw; /* Icono grande proporcional en pantallas pequeñas */
  }
}

/* Adaptaciones para pantallas aún más pequeñas (móviles) */
@media (max-width: 480px) {
  .side-bar {
      width: 10vw; /* Ancho aún más pequeño para pantallas móviles */
      padding: 0.5vw; /* Menor padding */
  }
  .hover-box {
      left: 8vw; /* Ajuste del recuadro para evitar desbordes */
  }
  .side-button-container .material-icons {
      font-size: 6vw; /* Ajustar íconos para pantallas móviles */
  }
  .large-icon {
      font-size: 7vw;
  }
}
