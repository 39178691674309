/* Estilos generales para la caja de la cita */
.quote_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch; /* Asegura que los elementos tengan la misma altura */
    width: 100%;
    height: fit-content;
    padding: 2rem 0;
}

.quote_txt::after,
.quote_txt::before {
    content: "\e244"; /* Iconos de Material Icons */
    font-family: "Material Icons";
    position: absolute;
    z-index: 0;
    font-size: 5rem;
    color: #0000008a;
}

.quote_txt::before {
    left: 4vw;
    top: 1rem;
    transform: rotate(180deg);
}

.quote_txt::after {
    bottom: 2rem;
    right: 1vw;
}

/* Estilos de la imagen */
.quote_img {
    display: block;
    width: 40vw;
    height: auto;
    z-index: 1;
    margin-right: 0; /* Elimina margen para que la imagen esté pegada al texto */
}

.quote_img img {
    width: 100%;
    height: 100%; /* Asegura que la imagen llene todo el contenedor */
    object-fit: cover; /* Se ajusta mejor que contain en este caso */
    border-radius: 10px 0 0 10px;
}

/* Estilos del texto de la cita */
.quote_txt {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto; /* Asegura que el contenedor de texto tenga la misma altura que la imagen */
    font-family: 'Myriad Pro Light';
    font-size: 100%;
    background: #2e2e2e;
    padding-inline: 5rem;
    /*padding: 10rem 5rem;*/
    /*padding-left: 5rem;*/
    color: #ffffff;
    box-sizing: border-box;
    text-align: left;
    border-radius: 0 10px 10px 0;
}

.quote_txt p {
    z-index: 1;
    text-align: right;
}

.quote_txt p:first-child {
    font-family: 'Myriad Pro semiBold';
    font-size: 1.5rem;
    margin: 0 2rem;
    text-align: center;
}

/* Animación para cuando el usuario se desplaza hacia la sección */
@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.quote_box.hidden {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.quote_box.visible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
