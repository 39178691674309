/* Carousel */

section.slide[data-s="carousel"] {
    overflow: hidden;
    /* height: calc(100% - 11.5rem); */
}

.box_carousel {
    display: flex;
}

.self_carousel {
    position: relative;
    display: inline-flex;
    flex-flow: row;
    overflow: hidden;
}

.img_carousel {
    position: absolute;
    display: block;
    height: 100%;
    width: 86vw;
}

.img_carousel picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.txt_carousel {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: 3rem 10vw;
    box-sizing: border-box;
    background: #00000087;
    justify-content: space-evenly;
    align-items: center;
}

.txt_carousel h2 {
    font-family: 'Myriad Pro Semibold';
    font-size: 2.7rem;
    margin-top: 0;
}

.box_carousel .txt_carousel .resume {
    font-family: 'Myriad Pro Bold';
    font-size: 1.5rem;
    text-align: justify;
    margin-bottom: 2rem;
    color: white;
    /* width: 50%; */
}

.box_carousel .txt_carousel .read .material-icons {
    vertical-align: middle;
    padding-right: 1rem;
}

.material-icons.white {
    color: white;
}

.box_carousel .read {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    font-family: 'Myriad Pro Semibold';
    color: white;
    /*width: fit-content;*/
    background-color: var(--main-red);
    padding: 0.5rem 1rem;
    /*margin: 0 0 2rem 0;*/
}