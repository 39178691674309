/* Carousel */

section.slide[data-s="info"] {
    height: fit-content;
    padding: 10rem 0rem;
}

.infoContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 65vh;
    width: 100%;
    background-color: #bf0000;
    background-image: linear-gradient( 335deg, #bd0d1e 23px, transparent 23px), linear-gradient( 155deg, #cd1627 23px, transparent 23px), linear-gradient( 335deg, var(--main-red) 23px, transparent 23px), linear-gradient( 155deg, #cd1627 23px, transparent 23px);
    background-size: 58px 58px;
    background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
}

.infoBox {
    display: inline-flex;
    position: relative;
    color: white;
    text-align: center;
    flex-basis: calc(50% - 3.5rem);
    flex-flow: column;
}

.infoBox.cliped {
    order: 1;
    /* -webkit-clip-path: circle(66% at 40% 48%);
    clip-path: circle(66% at 40% 48%); */
}

.infoBox.noCliped {
    order: 2;
    padding: 2rem 4rem 2rem 2rem;
}

.infoBox h1,
.infoBox h2,
.infoBox h3,
.infoBox span {
    display: block;
    margin: .5rem auto;
}

.infoBox.noCliped h1 {
    font-family: "Myriad Pro Bold";
    font-size: 3rem;
    padding-bottom: 3rem;
}

.infoBox.noCliped h2 {
    font-family: "Myriad Pro Light";
    font-size: 2rem;
    width: 100%;
}

.infoBox.noCliped h3 {
    font-family: "Myriad Pro regular";
    font-size: 2rem;
}

.infoBox.noCliped span {
    font-family: "Myriad Pro Bold";
    background: white;
    color: var(--main-red);
    font-size: 2rem;
    width: fit-content;
    line-height: 2;
    padding: 0 3rem;
    border-radius: 1rem;
}

.infoBox.noCliped picture {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoBox.cliped picture img,
.infoBox.noCliped picture img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.infoBox.cliped.L picture{
    height: 100%;
}