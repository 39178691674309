
section.slide[data-s="titulo"] {
  display: block;
  position: relative;
  padding: 0;
  height: 45vh;
}

section.slide[data-s="resena"],
section.slide[data-s="noticia"] {
  display: block;
  position: relative;
  min-height: 100vh;
  padding: 2rem 0;
}

.content_img {
  position: relative;
  display: block;
  overflow: hidden;
  height: inherit;
}

.content_img::after {
  background: linear-gradient(0deg, hsla(0, 0%, 5%, 0.6), hsla(0, 0%, 5%, 0.6));
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.content_img picture .material-icons {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  font-size: 5rem;
  color: gainsboro;
}

.content_img picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content_txt .titled.mid {
  font-size: 3rem;
}

.content_txt .titled.big {
  font-size: 4rem;
}

.content_txt .titled.bigX {
  font-size: 5.2rem;
}

.content_txt {
  position: absolute;
  bottom: 6vh;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--soft-white);
  width: calc(80vw - 4rem);
  z-index: 1;
}

.content-metainfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.content_txt .content-metainfo .dir-route {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Myriad Pro semiBold;
  font-size: 1rem;
  color: #b7b7b7;
  cursor: pointer;
  letter-spacing: 1px;
}

/*
.content_txt .dir-route::before {
    content: '\e88a';
    color: #c9c9c9;
    font-size: 1.1rem;
    font-family: 'Material Icons';
    padding-right: 0.45rem;
    vertical-align: -0.2rem;
}
*/

.content_txt .dir-route .material-icons {
  transform: scaleX(-1) translateY(2px) rotate(0deg);
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: #c9c9c9;
}

.content_txt .dir-route:hover::before,
.content_txt .dir-route:hover .material-icons,
.content_txt .dir-route:hover {
  color: rgb(240, 240, 240);
}

.content_txt .titled {
  display: block;
  font-family: "Myriad Pro semiBold";
  line-height: 1;
  text-align: justify;
  color: var(--soft-white);
  font-size: 3.2rem;
}

.resena_content,
.noti_content,
.noti_info {
  position: relative;
  display: block;
  width: 70%;
  margin: 0 auto;
  text-align: justify;
}

.noti_info {
  font-size: 1rem;
  font-family: "Myriad Pro bold";
  color: #c71122;
}

.resena_content,
.noti_content {
  font-family: "Myriad Pro Regular";
  font-size: 1.2rem;
}

.txt_font_size .min::after {
  content: "\e5c7";
}

.txt_font_size .min {
  font-size: 0.8rem;
}

.txt_font_size .max::after {
  content: "\e5c5";
}

.txt_font_size div::after {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #ffffff;
  font-size: 1rem;
  font-family: "Material Icons";
}

.txt_font_size {
  display: flex;
  font-family: "Myriad Pro Bold";
  position: absolute;
  bottom: -1.7rem;
  right: 0;
}

.txt_font_size div {
  display: flex;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  /* background: #f9f9f95e; */
  color: #ffffff;
  /* margin-left: .5rem; */
  padding: 0.2rem 0.5rem;
  padding-left: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.txt_font_size .sitemap,
.txt_font_size .home {
  padding: 0.2rem 0.5rem;
  font-size: 1.1rem;
}

.txt_font_size div:hover {
  opacity: 0.9;
}

.public_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Myriad Pro regular;
  color: #adadaa;
  font-size: 0.7rem;
  letter-spacing: 2px;
}

@media screen and (width < 600px) {
  .content_txt {
    margin-left: 5vh;
  }
  .content_txt .titled {
    font-size: 2.2rem !important;
  }
  .content-metainfo {
    flex-direction: column;
    align-items: flex-start;
  }
}
