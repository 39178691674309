/* Footer */

footer {
    position: relative;
    display: block;
    background: var(--blue-black);
    box-sizing: content-box;
    height: fit-content;
}

#footer_fix {
    padding: 2rem;
    /*padding-top: 5rem;*/
    position: relative;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin-right: 2vw;
}

/* #footer_fix .footer_back {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 19%, rgba(0,0,0,0.7931547619047619) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 19%, rgba(0,0,0,0.7931547619047619) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 19%, rgba(0,0,0,0.7931547619047619) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
} */

#footer_fix .footer_back {
    background: rgb(22, 22, 22);
    background: -moz-linear-gradient(180deg, rgb(22, 22, 22) 19%,rgba(27, 27, 27, 0.793) 100%);
    background: -webkit-linear-gradient(180deg, rgb(22, 22, 22) 19%, rgba(27, 27, 27, 0.793) 100%);
    background: linear-gradient(180deg, rgb(22, 22, 22) 19%, rgba(27, 27, 27, 0.793) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

#footer_fix .footer_img {
    background-image: url("https://webufpso.ufpso.edu.co/media/m0n_600.jpg");
    background-repeat: repeat; 
    z-index: 2;
}

#footer_fix .footer_img,
#footer_fix .footer_back {
    content: "";
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: 1;
}

.year {
    font-family: 'Myriad Pro light';
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-top: 3rem;

}

footer .footerBigBox {
    display: flex;
    width: 100%;
    padding-top: 0.5rem;
    flex-flow: column;
    z-index: 3;
    /*padding-top: 5rem;*/
}

footer .footerBox {
    position: relative;
    font-family: 'Myriad Pro Light';
    color: rgb(211, 209, 199);
    flex: fit-content;
    font-size: .9rem;
    margin: 1rem 0em 1em 2.5em;
    z-index: 3;
}

footer .footerBox .title {
    font-family: 'Myriad Pro Bold';
    color: rgb(139, 138, 133);
    letter-spacing: 2px;
    margin-bottom: 1.5rem !important;
    font-size: .8rem;
    text-transform: uppercase;
}

footer .footerBox .horario,
footer .footerBox .direccion,
footer .footerBox .contacto {
    margin-bottom: 1rem;
}

footer .footerBox .horario,
footer .footerBox .direccion,
footer .footerBox .politicas,
footer .footerBigBox .minister,
footer .footerBigBox .copyright,
footer .footerBigBox .year,
footer .footerBox .atencion_ciudadano,
footer .footerBox .contacto {
    letter-spacing: 1px;
}

footer .footerBox:nth-child(1) b,
footer .footerBox:nth-child(2) b {
    font-family: 'Myriad Pro regular';
    padding-right: 4px;
}

footer .footerBox:nth-child(1) span,
footer .footerBox:nth-child(2) span {
    margin-bottom: .1rem
}

footer .footerBox .politicas {
    display: block;
}

footer .footerBox .politicas a:hover {
    text-decoration: underline;
}

footer .footerBox picture {
    display: block;
    width: 100%;
    height: fit-content;
    text-align: center;
}

footer .logo_ufpso {
    padding: 1rem 0 2rem 0;
    text-align: center;
    flex-basis: 100%;
}


footer .logo_gob {
    padding: 1rem 0 2rem 0;
}

footer .logo_ufpso .logo img {
    max-width: 100%;
    width: 21rem;
}

footer .logo_ufpso .escudo img {
    max-width: 100%;
    width: 5rem;
    padding-left: 3rem;
}

footer .footerBigBox:last-child {
    display: flex;
    flex-flow: column;
    text-align: center;
    border-top: 2px solid #303030;
    margin-top: 1rem;
    padding-top: 1rem;
}

footer .footerBox hr {
    border: 0;
    border-bottom: 1px dashed #797979;
    background: #313131;
    margin: 1.5rem 0;
}

footer .footerBigBox span,
footer .footerBox span p,
footer .footerBox span {
    display: block;
}

footer .footerBox .atencion_ciudadano b {
    display: block;
    font-family: 'Myriad Pro regular';
}

.minister {
    font-family: 'Myriad Pro Light';
    font-size: .9rem;
    list-style: none;
    color: #d1d1d1;
    margin: auto 0;
    padding: 0;
    text-align: center;
}

.copyright {
    font-family: 'Myriad Pro Bold';
    font-size: 1.2rem;
    color: var(--soft-white);
    margin: auto;
    margin-bottom: 0.25rem;
    text-align: center;
    /*margin-top: 5rem;*/
}

.social_media {
    font-family: 'Myriad Pro semiBold';
    font-size: 1.5rem;
    color: var(--soft-white);
    list-style: none;
    text-align: center;
    padding: 0;
    flex-basis: 100%;
}

.social_media li {
    display: inline-block;
    margin: 1rem;
}

.social_media ul {
    padding: 0;
    margin: 0;
}

.social_media li img {
    width: 2.2rem;
    filter: invert(84%);
}

.logo_gob img {
    max-width: 20rem;
}

.logo_pie {
    position: relative;
    display: flex;
    width: -webkit-fill-available;
    height: 6rem;
    margin: auto;
    justify-content: center;
}

.logo_pie img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
}

.logos_aside {
    display: flex;
    position: relative;
    max-width: 18rem;
    background: #f1f1f1;
    border-radius: 1rem;
    padding: 1rem;
    margin: auto;
    margin-bottom: 1rem;
}

.logos_aside picture {
    display: block;
    width: auto !important;
    height: 4rem !important;
    margin: auto;
}

.logos_aside picture img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#iconoFacebook{
    color: white;
    font-size: 2em;
}

#iconoFacebook:hover{
    color: rgb(0, 140, 255);
}

#iconoInstagram{
    color: white;
    font-size: 2em;
}

#iconoInstagram:hover{
    color: rgb(215, 79, 233);
}

#iconoTwitter{
    color: white;
    font-size: 2em;
}

#iconoTwitter:hover{
    color: rgb(0, 140, 255);
}

#iconoYoutube{
    color: white;
    font-size: 2em;
}

#iconoYoutube:hover{
    color: rgb(0, 140, 255);
}

#iconoUfpso{
    color: white;
    font-size: 2em;
}

#iconoUfpso:hover{
    color: rgb(0, 140, 255);
}

@media only screen and (min-width: 0) and (max-width: 500px)  {
    .logo_gob img {
        max-width: 100%;
    }
}

@media only screen and (min-width: 0) and (max-width: 538px)  {
    footer .logo_ufpso .escudo img {
        padding-left: 0;
    }
}