/* Centrar el título y darle un margen superior del 10% */


.all-news-section h1 {
  text-align: center;
  margin-top: 7%;
  font-size: 2rem;
}


/* Centrar los botones de paginación */
.pagination {
  text-align: center;
  margin-top: 20px;
  padding: 1px 1px 15px 1px;
}

.pagination button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #a50d1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #a50d1b;
}

.box_news_list_card_all {
  /*margin-top:10px;
  margin-bottom:10px;*/
  cursor: pointer;
  padding: 10px;
  background-color: #e3e5e5; 
  border-radius: 10px;
  margin: 10px;
}

.slide_title_all{
padding: 7vh 0 0 0;
text-align: center;
}

.img_news_all{
display: block;
object-position: 50%;
height: 45vh !important;
}

.no-results {
font-size: 1.8rem;     /* Tamaño del texto */
color: #000000;        /* Color rojo para destacar */
text-align: center;    /* Centrar el texto */
margin-top: 20px;      /* Separación desde el elemento superior */
font-weight: bold;     /* Texto en negrita */
}